import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["cssFunction"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { style, util, compose } from "styled-system";
export var gradientStyle = function gradientStyle(_ref) {
  var cssFunction = _ref.cssFunction,
    options = _objectWithoutProperties(_ref, _excluded);
  return function (props) {
    var colors = util.get(props.theme, "colors") || {};
    var transformValue = transformGradient(cssFunction, colors);
    return style(_objectSpread(_objectSpread({}, options), {}, {
      transformValue: transformValue
    }))(props);
  };
};
var transformGradient = function transformGradient(cssFunction, colors) {
  return function (value) {
    var args = Array.isArray(value) ? value.map(transformColorStop(colors)).join(", ") : value;
    return "".concat(cssFunction, "(").concat(args, ")");
  };
};
var transformColorStop = function transformColorStop(colors) {
  return function (colorStop) {
    var getColor = function getColor(value) {
      return util.get(colors, value) || value;
    };
    if (Array.isArray(colorStop)) {
      var _colorStop = _slicedToArray(colorStop, 2),
        color = _colorStop[0],
        length = _colorStop[1];
      return [getColor(color), util.px(length)].join(" ");
    } else {
      return getColor(colorStop);
    }
  };
};
export var linearGradient = gradientStyle({
  prop: "linearGradient",
  cssProperty: "backgroundImage",
  cssFunction: "linear-gradient",
  key: "gradients"
});
export var radialGradient = gradientStyle({
  prop: "radialGradient",
  cssProperty: "backgroundImage",
  cssFunction: "radial-gradient",
  key: "gradients"
});
export var repeatingLinearGradient = gradientStyle({
  prop: "repeatingLinearGradient",
  cssProperty: "backgroundImage",
  cssFunction: "repeating-linear-gradient",
  key: "gradients"
});
export var repeatingRadialGradient = gradientStyle({
  prop: "repeatingRadialGradient",
  cssProperty: "backgroundImage",
  cssFunction: "repeating-radial-gradient",
  key: "gradients"
});
export default compose(linearGradient, radialGradient, repeatingLinearGradient, repeatingRadialGradient);