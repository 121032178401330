import _defineProperty from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { FORM_ERROR } from "final-form";
import set from "lodash/set";
import normalizeInputErrors from "./normalizeInputErrors";
var normalizeErrors = function normalizeErrors(errors, prefix) {
  var normalizedErrors = errors.reduce(function (acc, _ref) {
    var message = _ref.message,
      _ref$extensions = _ref.extensions,
      code = _ref$extensions.code,
      exception = _ref$extensions.exception;
    var error = exception && exception.inputErrors ? normalizeInputErrors(exception.inputErrors, prefix) : _defineProperty({}, FORM_ERROR, message);
    return _objectSpread(_objectSpread({}, acc), error);
  }, {});
  return prefix ? set({}, prefix, normalizedErrors) : normalizedErrors;
};
export default normalizeErrors;