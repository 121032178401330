import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var filterPropertiesByKey = function filterPropertiesByKey(data, filter) {
  var slug = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  var filterFn = function filterFn(key, value, filter, slug) {
    if (filter.includes(slug) && lastInSlug(slug) === key) {
      return {};
    }
    if (Array.isArray(value)) {
      return _defineProperty({}, key, arrFilter(value, filter, slug));
    }
    if (typeof value === "object" && value !== null) {
      return _defineProperty({}, key, objFilter(value, filter, slug));
    }
    return _defineProperty({}, key, value);
  };
  var lastInSlug = function lastInSlug(str) {
    var slugArr = str.split(".");
    return slugArr[slugArr.length - 1];
  };
  var arrFilter = function arrFilter(data, filter, slug) {
    return data.map(function (el) {
      return filterPropertiesByKey(el, filter, slug);
    });
  };
  var objFilter = function objFilter(data, filter, slug) {
    return Object.entries(data).reduce(function (prev, _ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
        k = _ref5[0],
        v = _ref5[1];
      return _objectSpread(_objectSpread({}, prev), filterFn(k, v, filter, slug ? "".concat(slug, ".").concat(k) : k));
    }, {});
  };
  if (typeof data === "object" && data !== null) return objFilter(data, filter, slug);
  return data;
};
export default filterPropertiesByKey;