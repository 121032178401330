import _defineProperty from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var pickProps = function pickProps(object, whitelist) {
  var filterFn = Array.isArray(whitelist) ? function (key) {
    return whitelist.includes(key);
  } : whitelist;
  return Object.entries(object).reduce(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 2),
      selected = _ref3[0],
      rejected = _ref3[1];
    var _ref4 = _slicedToArray(_ref2, 2),
      key = _ref4[0],
      value = _ref4[1];
    return filterFn(key) ? [_objectSpread(_objectSpread({}, selected), {}, _defineProperty({}, key, value)), rejected] : [selected, _objectSpread(_objectSpread({}, rejected), {}, _defineProperty({}, key, value))];
  }, [{}, {}]);
};
export default pickProps;