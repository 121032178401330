import _defineProperty from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "/workspace/common/temp/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var _excluded = ["blacklist"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React from "react";
import { styles, util } from "styled-system";
import styled from "@emotion/styled";
var css = function css(props) {
  return props.css;
};
var omit = function omit(obj, blacklist) {
  var next = {};
  for (var key in obj) {
    if (blacklist.indexOf(key) > -1) continue;
    next[key] = obj[key];
  }
  return next;
};
var styleKeys = Object.keys(styles).filter(function (key) {
  return typeof styles[key] === "function";
});
var propNames = styleKeys.reduce(function (a, key) {
  var names = Object.keys(styles[key].propTypes);
  return [].concat(_toConsumableArray(a), names);
}, []);

// private blacklist
var _blacklist = ["css", "is", "tag", "extend"].concat(_toConsumableArray(propNames));
var base = function base(props) {
  return props.extend || props.tag || props.is || "div";
};
var tag = React.forwardRef(function (_ref, ref) {
  var _ref$blacklist = _ref.blacklist,
    blacklist = _ref$blacklist === void 0 ? [] : _ref$blacklist,
    props = _objectWithoutProperties(_ref, _excluded);
  var Base = base(props);
  var next = omit(props, typeof Base === "string" ? [].concat(_toConsumableArray(_blacklist), _toConsumableArray(blacklist)) : _blacklist);
  return React.createElement(Base, _objectSpread(_objectSpread({}, next), {}, {
    ref: ref
  }));
});
var getPropTypes = function getPropTypes(funcs) {
  return funcs.filter(function (fn) {
    return typeof fn === "function" && typeof fn.propTypes === "object";
  }).reduce(function (a, fn) {
    return _objectSpread(_objectSpread({}, a), fn.propTypes);
  }, {});
};
var system = function system() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  for (var _len = arguments.length, keysOrStyles = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    keysOrStyles[_key - 1] = arguments[_key];
  }
  var funcs = keysOrStyles.map(function (key) {
    return styles[key] || key;
  });
  var Component = styled(tag)(funcs, css);
  var baseProps = util.get(props, "extend.defaultProps") || {};
  Component.defaultProps = _objectSpread(_objectSpread({}, baseProps), props);
  Component.propTypes = getPropTypes(funcs);
  Component.systemComponent = true;
  return Component;
};
export default system;